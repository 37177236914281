import {MapContainer, TileLayer} from "react-leaflet"
import React from "react"
import {Map as LeafletMap} from "leaflet"
import StationsMapStations from "./StationsMapStations"
import MapZoomControl from "./MapZoomControl";
import { mapDefaultCenter, mapDefaultZoom } from "../utils/constants"

interface IProps {
    isPresentation: boolean
}

interface IStations {
    map?: LeafletMap
}

class StationsMap extends React.Component<IProps, IStations> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            map: undefined
        }
    }

    zoomIn = () => {
        const parentMap = this.state.map!; 
        parentMap.zoomIn()
    }
    zoomOut = () => {
        const parentMap = this.state.map!; 
        parentMap.zoomOut()
    }
    
    scroll = (dir: number) => { //0 = top, 1 = right, 2 = bottom, 3 = left
        const parentMap = this.state.map!; 

        let factorHorizontal = 0;
        let factorVertical = 0;
        switch (dir) {
            case 0:
                factorVertical = 0.06;
                break;
            case 1:
                factorHorizontal = 0.18;
                break;
            case 2:
                factorVertical = -0.06;
                break;
            case 3:
                factorHorizontal = -0.18;
                break;
        } 
        if (factorVertical !== 0)
            parentMap.flyTo([parentMap.getCenter().lat + factorVertical / parentMap.getZoom(), parentMap.getCenter().lng]);
        else
            parentMap.flyTo([parentMap.getCenter().lat, parentMap.getCenter().lng + factorHorizontal / parentMap.getZoom()]);
    }
    
    reset = () => {
        const parentMap = this.state.map!; 

       parentMap.flyTo(mapDefaultCenter, mapDefaultZoom);
    }

    render() {
        return (
            <div>
                <MapContainer
                    center={mapDefaultCenter}
                    zoom={mapDefaultZoom}
                    zoomControl={false}
                    scrollWheelZoom={false}
                    whenCreated={(map: LeafletMap) => this.setState({map: map})}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}@4x.png"/>

                    <StationsMapStations map={this.state.map}/>

                    {!this.props.isPresentation ? (
                        <MapZoomControl />
                    ) : null }
                </MapContainer>
            </div>
        )
    }
}

export default StationsMap;