import React, {ChangeEvent} from "react"
import {VolumeDown, VolumeUp, PlayArrow, DepartureBoard} from "@material-ui/icons";
import {Checkbox, Fab, FormControlLabel, FormGroup, Grid, Slider, Typography} from "@material-ui/core";
import StationsMapSchedule from "./StationsMapSchedule";
import {readableDateFromDate, readableTimeFromDate} from "../utils/time";
import {setVolume, startAudio, toggleBackgroundSound, toggleDeparturesSound} from "../utils/generalAudio";
import { ReactComponent as LogoSVG } from '../media/logo.svg';
import StationsMapStationSelection from "./StationsMapStationSelection";
import Demo from "./Demo";

interface IProps {
    isPresentation: boolean
}

interface IControlState {
    currentDate: Date,
    volume: number,
    scheduleShown: boolean,
    selectedStationsShown: boolean,
    playShown: boolean,
    departuresSound: boolean,
    backgroundSound: boolean
}

class StationsMapControls extends React.Component<IProps, IControlState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            currentDate: new Date(),
            volume: 100,
            scheduleShown: false,
            selectedStationsShown: false,
            playShown: true,
            departuresSound: true,
            backgroundSound: true
        }
        this.startToneAudio()
    }

    startToneAudio = () => {
        startAudio().then(() => {
            this.setState({
                playShown: false
            })
        });
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                currentDate : new Date()
            })
        }, 1000)
    }


    handleVolumeChange = (event: ChangeEvent<{}> | null, newValue: number | number[]) => {
        const newValueNumber = newValue as number
        this.setState({
            volume: newValueNumber
        })
        setVolume(newValueNumber / 100)
    };

    muteVolume = () => {
        this.handleVolumeChange(null, 0)
    };
    maximizeVolume = () => {
        this.handleVolumeChange(null, 100)
    };

    handleBackgroundChange = (event: any) => {
        toggleBackgroundSound(event.target.checked)
        this.setState({
            backgroundSound: event.target.checked
        })
    };
    handleDeparturesChange = (event: any) => {
        toggleDeparturesSound(event.target.checked)
        this.setState({
            departuresSound: event.target.checked
        })
    };

    scheduleClick = () => {
        this.setState({
            scheduleShown: !this.state.scheduleShown
        })
    }

    reset = () => {
        this.setState({
            scheduleShown: false
        })
    }


    render() {
        return (
            <div className={this.props.isPresentation ? "presentationControls" : ""}>
                <Grid id={"header"} container spacing={2}>
                    <Grid item>
                        <LogoSVG className={"logo"} />
                    </Grid>
                    <Grid item className={"title"}>
                        <Typography variant="h4">Weil Wir Dich Hören</Typography>
                    </Grid>
                </Grid>


                {this.state.playShown ? (
                    <Fab id={"fabPlay"} color={"primary"} variant="extended" aria-label="play" onClick={this.startToneAudio}>
                        <PlayArrow />
                        &nbsp;Ton abspielen
                    </Fab>
                ) : null }

                {!this.props.isPresentation ? (
                    <Fab id={"fabSchedule"} color={"secondary"} aria-label="schedule" onClick={this.scheduleClick}>
                        <DepartureBoard />
                    </Fab>
                ) : null }

                {this.state.scheduleShown ? <StationsMapSchedule/> : null }

                <div id={"currentDate"}>
                    <Typography className={"date"} variant="h6">
                        {readableDateFromDate(this.state.currentDate)}
                    </Typography>
                    <Typography className={"time"} variant="h4">
                        {readableTimeFromDate(this.state.currentDate)}
                    </Typography>
                </div>
                {!this.props.isPresentation ? (
                    <div id={"volume"}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <VolumeDown cursor={"pointer"} onClick={this.muteVolume} />
                            </Grid>
                            <Grid item xs>
                                <Slider value={this.state.volume} onChange={this.handleVolumeChange} />
                            </Grid>
                            <Grid item>
                                <VolumeUp cursor={"pointer"} onClick={this.maximizeVolume} />
                            </Grid>
                        </Grid>
                    </div>
                ) : null }
                {!this.props.isPresentation ? (
                    <div id={"toggleSounds"}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.departuresSound}
                                        onChange={this.handleDeparturesChange}
                                    />
                                }
                                label="Abfahrten"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.backgroundSound}
                                        onChange={this.handleBackgroundChange}
                                        color="primary"
                                    />
                                }
                                label="Hintergrund"
                            />
                        </FormGroup>
                    </div>
                ) : null }

                {!this.props.isPresentation ? (
                    <StationsMapStationSelection />
                ) : null }

                {!this.props.isPresentation ? (
                    <Demo />
                ) : null }
            </div>
        )
    }
}

export default StationsMapControls;