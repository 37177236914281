import {Circle} from "react-leaflet"
import React from "react"
import { Map as LeafletMap } from "leaflet";
import {StationType} from "../models/Station"
import { DepartureType } from "../models/Departure"
import { stationsStore } from "../models/StationsStore";
import { vehicles } from "../utils/constants";
import { playDepartureSound } from "../utils/generalAudio";
import {frequenciesFromVehicleDelay, volumeFromDelay} from "../utils/vehicleSounds";

interface IProps {
    station: StationType,
    departure: DepartureType,
    departurePassed: Function,
    map?: LeafletMap
}

function secondsDiff(d1: Date, d2: Date) {
    return Math.floor((d2.getTime() - d1.getTime()) / 1000)
}
function millisecondsDiff(d1: Date, d2: Date) {
    return d2.getTime() - d1.getTime()
}

interface IState {
    isVisible: boolean,
    currentRadius: number,
    markerOpaque: boolean
}

const defaultRadius = 100
const defaultGrowingTime = 5

class StationsMapDeparture extends React.Component<IProps, IState> {
    interval : number = -1;

    constructor(props: IProps) {
        super(props)
        this.state = {
            isVisible: false,
            markerOpaque: false,
            currentRadius: this.currentRadius
        }
    }

    tick() {
        const currentDate = new Date()
        if(this.props.departure.startTime == null) {
            if(this.props.departure.date <= currentDate) {
                this.props.departure.started()
                if (this.stationIsVisible) {
                    if (this.props.station.selected) {
                        this.props.departurePassed(this.props.departure)
                    }
                    this.playSound(this.props.station.id, this.props.departure.product, this.props.departure.delay)
                }
            }
        } else {
            this.setState({
                isVisible: true,
                markerOpaque: this.props.station.selected,
                currentRadius: this.currentRadius
            })
            if (secondsDiff(this.props.departure.startTime, currentDate) > this.growingTime) {
                this.setState({
                    isVisible: false
                })
                this.props.departure.remove()
            }
        }
    }

    componentDidMount() {
        // @ts-ignore
        this.interval = setInterval(() => this.tick(), 1);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    get color() : string {
        if(this.props.departure.delay === 0)
            return "green"
        else if(this.props.departure.delay <= 2)
            return "yellow"
        else
            return "red"
    }

    get maximumRadius() : number {
        return defaultRadius +
            (this.props.departure.delay >= 5
                ? 125
                : Math.pow(this.props.departure.delay,3))
    }

    get growingTime(): number {
        return defaultGrowingTime +
            (this.props.departure.delay >= 5
                ? 5
                : Math.pow(this.props.departure.delay,2) * 2 / 10)
    }

    get currentRadius(): number {
        return Math.min(this.maximumRadius,
            (millisecondsDiff(this.props.departure.date, new Date()) /
                this.growingTime / 1000) * this.maximumRadius)
    }

    // Sound output
    get stationIsVisible(): boolean {
        return this.mapBounds.contains(this.props.station.position)
    }

    get mapBounds(): L.LatLngBounds {
        return this.props.map!!.getBounds()
    }

    get horizontalPositionOnMap(): number {
        const mapBounds = this.mapBounds
        const west = mapBounds.getWest()
        const east = mapBounds.getEast()
        return (this.props.station.position[1] - west) / (east - west)
    }

    playSound(stationId: string, vehicleType: string, delay: number) {
        const frequency = frequenciesFromVehicleDelay(vehicleType, delay)
        const volume = volumeFromDelay(delay)
        const instrument = vehicles[vehicleType].instrument
        const station = stationsStore.findStation(stationId)

        if (station != null) {
            playDepartureSound(instrument, frequency, volume, (this.horizontalPositionOnMap * 2) - 1, station.selected)
        }
    }

    render() {
        return this.state.isVisible ? (
            <Circle
                center={this.props.station.position}
                color={this.color}
                radius={this.currentRadius}
                opacity={this.state.markerOpaque ? 1 : .1} />
        ) : null
    }
}

export default StationsMapDeparture
