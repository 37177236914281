import * as Tone from "tone"
import {PanVol} from "tone"
import SampleLibrary from "./tonejs-instruments"

const instruments = ["piano", "flute", "contrabass", "cello", "bassoon", "french-horn"]

let samples: any = []
let instrumentPans : any = []

let instrumentNextSampler: Record<string, number> = {}

let globalVolume = new Tone.Volume(0).toDestination()

let departuresVolume = new Tone.Volume(0)
departuresVolume.connect(globalVolume)

let unselectedDeparturesVolume = new Tone.Volume(-25)
unselectedDeparturesVolume.connect(globalVolume)

let backgroundVolume = new Tone.Volume(0)
backgroundVolume.connect(globalVolume)

// initialize cycles
for(let i = 0; i < 10; i++) {
    samples[i] = {}
    instrumentPans[i] = {}
}
for(let instrument of instruments) {
    instrumentNextSampler[instrument] = 0
}

// initialize background sampler
const backgroundSampler = SampleLibrary.load({
    instruments: "violin"
})
backgroundSampler.connect(backgroundVolume)
let backgroundFrequency: string = ""

let demoVolume = new Tone.Volume(0).toDestination()
demoVolume.mute = true
let demoSampler: any = null

async function startAudio() {
    await Tone.start()
}

function playDepartureSoundWithSampler(instrument: string, frequency: (number | string) | (number | string)[], volume: number, pan: number, selectedStation: boolean) {
    const nextIntrumentSamplerNumber = instrumentNextSampler[instrument]
    const nextIntrumentSampler = samples[nextIntrumentSamplerNumber][instrument]

    if (nextIntrumentSampler.loaded) {
        if (!(instrument in instrumentPans[nextIntrumentSamplerNumber])) {
            instrumentPans[nextIntrumentSamplerNumber][instrument] = new PanVol(pan, volume)
            instrumentPans[nextIntrumentSamplerNumber][instrument].connect(departuresVolume)
            nextIntrumentSampler.connect(instrumentPans[nextIntrumentSamplerNumber][instrument])
        }

        const panVol: PanVol = instrumentPans[nextIntrumentSamplerNumber][instrument]

        panVol.disconnect()
        if(selectedStation) {
            panVol.connect(departuresVolume)
        } else {
            panVol.connect(unselectedDeparturesVolume)
        }
        panVol.pan.value = pan
        panVol.volume.value = volume
        nextIntrumentSampler.release = .5
        nextIntrumentSampler.triggerAttackRelease(frequency, 2)

        //console.log("played " + instrument + " with vol " + volume + " at " + pan)

        instrumentNextSampler[instrument]++
        if (instrumentNextSampler[instrument] === 10) instrumentNextSampler[instrument] = 0
    }
}

function playDepartureSound(instrument: string, frequency: (number | string) | (number | string)[], volume: number, pan: number, selectedStation: boolean) {
    const nextInstrumentSamplerNumber = instrumentNextSampler[instrument]

    if(instrument in samples[nextInstrumentSamplerNumber]) {
        playDepartureSoundWithSampler(instrument, frequency, volume, pan, selectedStation)
    } else {
        samples[nextInstrumentSamplerNumber][instrument] = SampleLibrary.load({
            instruments: instrument
        })
    }
}

function playVehicleDemoSound(instrument: string, frequency: (number | string) | (number | string)[]) {
    if (demoSampler[instrument].loaded) {
        demoSampler[instrument].release = .5
        demoSampler[instrument].triggerAttackRelease(frequency, 2)
    }
}

function playDelaySound(generalDelay: number, numberSelectedStations: number) {
    if(backgroundSampler.loaded) {
        let frequency = "C1"
        if(generalDelay > 0.5) {
            if(generalDelay < 0.7) {
                frequency = "D1"
            } else if(generalDelay < 0.9) {
                frequency = "E1"
            } else if(generalDelay < 1.0) {
                frequency = "F1"
            } else if(generalDelay < 1.1) {
                frequency = "G1"
            } else if(generalDelay < 1.2) {
                frequency = "A1"
            } else if(generalDelay < 1.3) {
                frequency = "B1"
            } else if(generalDelay < 1.4) {
                frequency = "C2"
            } else if(generalDelay < 1.5) {
                frequency = "D2"
            } else if(generalDelay < 1.6) {
                frequency = "E2"
            } else if(generalDelay < 1.7) {
                frequency = "F2"
            } else if(generalDelay < 1.8) {
                frequency = "G2"
            } else if(generalDelay < 2.0) {
                frequency = "A2"
            } else if(generalDelay < 2.1) {
                frequency = "B2"
            } else if(generalDelay < 2.2) {
                frequency = "C3"
            } else {
                frequency = "D3"
            }
        }
        backgroundSampler.volume.value =  numberSelectedStations > 50 ? -30 : -40
        if(backgroundFrequency === "" || frequency !== backgroundFrequency) {
            backgroundSampler.attack = .5
            backgroundSampler.release = .5
            backgroundSampler.triggerRelease(backgroundFrequency)
            backgroundSampler.triggerAttack(frequency)
            backgroundFrequency = frequency
        } else {
            backgroundSampler.triggerAttack(frequency)
        }
    }
}

function toggleDeparturesSound(play: boolean) {
    departuresVolume.mute = !play
    unselectedDeparturesVolume.mute = !play
}
function toggleBackgroundSound(play: boolean) {
    backgroundVolume.mute = !play
}


function setVolume(percentage: number) {
    if(percentage <= 0) globalVolume.mute = true
    else {
        globalVolume.mute = false
        globalVolume.volume.value = -30 + (percentage * 30)
    }
}

function initializeDemoSampler() {
    demoSampler = SampleLibrary.load({
        instruments: instruments
    })
    instruments.forEach(instrument => {
        demoSampler[instrument].connect(demoVolume)
    })
}

function muteVolumeForDemo() {
    const previouslyMuted = globalVolume.mute
    globalVolume.mute = true
    demoVolume.mute = false
    if(demoSampler == null) {
        initializeDemoSampler()
    }
    return previouslyMuted
}
function unmuteVolumeForDemo() {
    globalVolume.mute = false
    demoVolume.mute = true
}

export {
    startAudio,
    playDepartureSound,
    playDelaySound,
    playVehicleDemoSound,
    toggleDeparturesSound,
    toggleBackgroundSound,
    setVolume,
    muteVolumeForDemo,
    unmuteVolumeForDemo
}