import bus from "../media/bus.gif";
import subway from "../media/ubahn.gif";
import suburban from "../media/sbahn.png";
import regional from "../media/bahn_gro.gif";
import express from "../media/fernverkehr.png";
import tram from "../media/tram.gif";
import ferry from "../media/faehre.gif";

const NUMBER_OF_RECENT_DEPARTURES = 30

const trafficProductIcons : Record<string, string> = {
    bus: bus,
    subway: subway,
    suburban: suburban,
    regional: regional,
    express: express,
    tram: tram,
    ferry: ferry,
};

interface IVehicle {
    instrument: string,
    frequency: string,
    delayFrequency: (string | number | string[])[]
}
const vehicles: Record<string , IVehicle> = {
    "bus": {
        instrument: "piano",
        frequency: "G2",
        delayFrequency: [["G3","A3"], ["G4","A4"], ["G5","A5"]]
    },
    "express": {
        instrument: "french-horn",
        frequency: "C2",
        delayFrequency: [["C3","D3"], ["C4","D4"], ["C5","D5"]]
    },
    "ferry": {
        instrument: "piano",
        frequency: "G2",
        delayFrequency: [["G3","A3"], ["G4","A4"], ["G5","A5"]]
    },
    "regional": {
        instrument: "cello",
        frequency: "C2",
        delayFrequency: [["C3","D3"], ["C4","D4"], ["C5","D5"]]
    },
    "suburban": {
        instrument: "bassoon",
        frequency: "G2",
        delayFrequency: [["G2","A2"], ["G3","A3"], ["G4","A4"]]
    },
    "subway": {
        instrument: "contrabass",
        frequency: "E2",
        delayFrequency: [["E3","F3"], ["E4","F4"], ["E5","F5"]]
    },
    "tram": {
        instrument: "flute",
        frequency: "E2",
        delayFrequency: [["E2","F2"], ["E3","F3"], ["E4","F4"]]
    }
};

const mapDefaultCenter: L.LatLngTuple = [52.525240541323384, 13.369584388695555]
const mapDefaultZoom = 12


export {NUMBER_OF_RECENT_DEPARTURES, trafficProductIcons, vehicles, mapDefaultCenter, mapDefaultZoom};