import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStationsStore} from "../models/StationsStore";
import {Fab} from "@material-ui/core";
import {EditLocation} from "@material-ui/icons";
// @ts-ignore
import MultiSelect from "@kenshooui/react-multi-select";
import {StationType} from "../models/Station";


interface IStation {
    id: string,
    label: string
}

const multiselectMessages = {
    searchPlaceholder: "Station suchen...",
    noItemsMessage: "Keine Stationen...",
    noneSelectedMessage: "Keine Station ausgewählt",
    selectedMessage: "ausgewählt",
    selectAllMessage: "Alle auswählen",
    clearAllMessage: "Alle leeren",
}

const StationsMapStationSelection: React.FC = observer(() => {
    const {stations} = useStationsStore()

    const [selectShown, setSelectShown] = useState(false)

    const selectedStationsClick = () => {
        setSelectShown(!selectShown)
    }
    const selectedStationsChange = (newSelectedStations: Array<IStation>) => {
        const newSelectedStationIds = newSelectedStations.map(newSelectedStation => newSelectedStation.id);
        stations.forEach((station: StationType) => {
            station.setSelection(newSelectedStationIds.includes(station.id))
        })
    }

    let allStations: Array<IStation> = []
    let selectedStations: Array<IStation> = []
    stations.forEach((station: StationType) => {
        const stationMultiSelectItem = {
            id: station.id,
            label: station.name
        }
        allStations.push(stationMultiSelectItem)
        if(station.selected)
            selectedStations.push(stationMultiSelectItem)
    })

    return (
        <div>
            <Fab id={"fabSelectStations"} color={"secondary"} aria-label="stations" onClick={selectedStationsClick}>
                <EditLocation />
            </Fab>
            {selectShown ? <div id={"selectStations"}>
                <MultiSelect
                    items={allStations}
                    selectedItems={selectedStations}
                    onChange={selectedStationsChange}
                    messages={multiselectMessages}
                />
            </div> : null }
        </div>
    )
})

export default StationsMapStationSelection;