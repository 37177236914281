import React from 'react';
import {Fab} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DepartureBoardIcon from '@material-ui/icons/DepartureBoard';
import RemoteDemo from "./RemoteDemo";

interface IProps {
    sendMessage: any
};

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const RemoteSpecialControls : React.FC<IProps> = ({sendMessage}) => {

    const classes = useStyles();

    const reset = () => {
        sendMessage({
            "type": "CONTROL_RESET",
        });
    }

    const toggleLog = () => {
        sendMessage({
            "type": "CONTROL_LOG_TOGGLE",
        });
    }


    return(
    <div className={"remoteSpecialControls"}>
        <Fab id={"fabLog"} color="primary" variant="extended" size="large" className={classes.margin} aria-label="toggleLog" onClick={() => toggleLog()}>
            <DepartureBoardIcon className={classes.extendedIcon} /> Fahrplan ein-/ausblenden
        </Fab>
        <RemoteDemo />
        <Fab id={"fabReset"} variant="extended" size="large"  className={classes.margin} aria-label="reset" onClick={() => reset()}>
            <SettingsBackupRestoreIcon className={classes.extendedIcon} /> Zurücksetzen
        </Fab>
    </div>
    )
}

export default RemoteSpecialControls;