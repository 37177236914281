import { useContext, createContext } from "react";
import { types, Instance, onSnapshot } from "mobx-state-tree";
import Station, {StationType} from "./Station";

const StationsStore = types.model("StationsStore", {
    stations: types.array(Station)
}).actions(self => {
    return {
        addStation(station: StationType) {
            if(!self.stations.some(existingStation => existingStation.id === station.id)) {
                self.stations.push(station)
            }
        }
    }
}).views(self => {
    return {
        findStation(stationId: string) {
            return self.stations.find(station => station.id === stationId) || null
        },

        get numberSelectedStations() {
            return self.stations.filter(station => station.selected).length
        }        
    }
})


let initialState = StationsStore.create({
    stations: []
})

/*
const date = new Date();
date.setSeconds(date.getSeconds() + 3)

const exampleDeparture = Departure.create({
    date: date,
    delay: 2,
    product: "bus"
})

const exampleStation = Station.create({
    id: "1",
    name: "Berlin Hbf",
    lat: 52.525240541323384,
    long: 13.369584388695555,
    departures: [exampleDeparture]
})

let initialState = StationsStore.create({
    stations: [exampleStation]
});


setTimeout(() => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 3)

    initialState.stations[0].addDeparture(Departure.create({
        delay: 3,
        date: date,
        product: "ferry"
    }))

    initialState.stations[0].addDeparture(Departure.create({
        delay: 0,
        date: new Date(),
        product: "subway"
    }))
}, 6000)*/

/*const data = localStorage.getItem('stationsState');
if (data) {
    const json = JSON.parse(data);
    if (StationsStore.is(json)) {
        initialState = StationsStore.create(json);
    }
}*/

export const stationsStore = initialState;

onSnapshot(stationsStore, snapshot => {
    //console.log("Snapshot: ", snapshot);
    //localStorage.setItem('stationsState', JSON.stringify(snapshot));
});

export type StationsStoreInstance = Instance<typeof StationsStore>;
const StationsStoreContext = createContext<null | StationsStoreInstance>(null);

export const StationsProvider = StationsStoreContext.Provider;

export function useStationsStore() {
    const store = useContext(StationsStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

