import {useMap} from "react-leaflet";
import {Fab} from "@material-ui/core";
import {Add, Remove} from "@material-ui/icons";
import React from "react";

function MapZoomControl() {
    const parentMap = useMap()

    const zoomIn = () => {
        parentMap.zoomIn()
    }
    const zoomOut = () => {
        parentMap.zoomOut()
    }

    return (
        <div>
            <Fab id={"fabZoomIn"} size="small" aria-label="zoomIn" onClick={zoomIn}>
                <Add />
            </Fab>
            <Fab id={"fabZoomOut"} size="small" aria-label="zoomOut" onClick={zoomOut}>
                <Remove />
            </Fab>
        </div>
    )
}

export default MapZoomControl