import React from 'react';
import '../styles/Remote.css';
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import RemoteMapControls from './RemoteMapControls';
import RemoteSpecialControls from './RemoteSpecialControls';
import { w3cwebsocket as W3CWebSocket } from "websocket"

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e79f06'
        },
        secondary: {
            main: '#f0d723'
        }
    },
    typography: {
        fontFamily: [
            'Vegur',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

interface IProps {
    presentationId: string | null
}

const WS_ADDRESS = (process.env.NODE_ENV === 'production') ? 'wss://weil-wir-dich-hoeren.de' : 'ws://localhost:8999';
const client = new W3CWebSocket(WS_ADDRESS);

const Remote : React.FC<IProps> = ({presentationId}) => {

    client.onopen = () => {
        console.log('WebSocket Client Connected');
    };

    const sendMessage = (message: any) => {
        client.send(JSON.stringify({
            ...message,
            id: presentationId
        }));
    };

    return (
        <ThemeProvider theme={theme}>
            <div className={"remote"}>
                <RemoteMapControls sendMessage={sendMessage} />
                <RemoteSpecialControls sendMessage={sendMessage} />
            </div>
        </ThemeProvider>
    );
}

export default Remote;
