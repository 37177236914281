import {getParent, Instance, types} from "mobx-state-tree";
import {StationType} from "./Station";

const Departure = types.model({
    id: types.identifier,
    date: types.Date,
    delay: types.number,
    product: types.string,
    lineName: types.string,
    direction: types.string,
    stationName: types.optional(types.string, ""),

    startTime: types.optional(types.maybeNull(types.Date), null)
}).actions(self => {
    return {
        started() {
            self.startTime = new Date()
        },
        remove() {
            (getParent(self, 2) as StationType).removeDeparture(self)
        }
    }
});

export default Departure
export type DepartureType = Instance<typeof Departure>
