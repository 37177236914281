import { useContext, createContext } from "react";
import { types, Instance, onSnapshot } from "mobx-state-tree";
import Departure, {DepartureType} from "./Departure";
import {NUMBER_OF_RECENT_DEPARTURES} from "../utils/constants";

const ScheduleStore = types.model("ScheduleStore", {
    recentDepartures: types.array(Departure)
}).actions(self => {
    return {
        addRecentDeparture(departure: DepartureType) {
            self.recentDepartures.push(departure)
            if(self.recentDepartures.length > NUMBER_OF_RECENT_DEPARTURES) {
                self.recentDepartures.shift()
            }
        }
    }
})


let initialState = ScheduleStore.create({
    recentDepartures: []
})

export const scheduleStore = initialState;

onSnapshot(scheduleStore, snapshot => {
    //console.log("Snapshot: ", snapshot);
    //localStorage.setItem('stationsState', JSON.stringify(snapshot));
});

export type ScheduleStoreInstance = Instance<typeof ScheduleStore>;
const ScheduleStoreContext = createContext<null | ScheduleStoreInstance>(null);

export const ScheduleProvider = ScheduleStoreContext.Provider;

export function useScheduleStore() {
    const store = useContext(ScheduleStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

