import React from "react";
import {Map as LeafletMap} from "leaflet";
import {observer} from "mobx-react";
import {useStationsStore} from "../models/StationsStore";
import StationsMapStation from "./StationsMapStation";

interface IProps {
    map?: LeafletMap,
}

const StationsMapStations: React.FC<IProps> = observer(({map}) => {
    const {stations} = useStationsStore()

    return (
        <div>
            {stations.map((station) =>
                <StationsMapStation
                    key={station.id}
                    map={map}
                    station={station} />
            )}
        </div>
    )
})

export default StationsMapStations;