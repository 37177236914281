import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './styles/fonts.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Remote from './components/Remote';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/"> <App presentationId={null}/> </Route>
      <Route path="/fluxus"> <Remote presentationId={"fluxus"} /> </Route>,
      <Route path="/fluxus-presentation"> <App presentationId={"fluxus"}/> </Route>,
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
