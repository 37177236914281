import {destroy, Instance, types} from "mobx-state-tree"
import Departure, {DepartureType} from "./Departure";

const Station = types.model({
    id: types.identifier,
    name: types.string,
    lat: types.number,
    long: types.number,
    departures: types.array(Departure),
    selected: types.optional(types.boolean, true)
}).actions(self => {
    return {
        addDeparture(departure: DepartureType) {
            self.departures.push(departure)
        },
        removeDeparture(departure: any) {
            destroy(departure)
        },
        setSelection(selected: boolean) {
            self.selected = selected
        }
    }
}).views(self => {
    return {
        get position(): L.LatLngTuple {
            return [self.lat, self.long]
        },
        findDeparture(departureId: string) {
            return self.departures.find(departure => departure.id === departureId) || null
        }
    }
})

export default Station
export type StationType = Instance<typeof Station>
