import React from "react"
import {Grid, ListItem, ListItemText} from "@material-ui/core";
import {observer} from "mobx-react";
import {DepartureType} from "../models/Departure";
import {readableTimeFromDate} from "../utils/time";
import {trafficProductIcons} from "../utils/constants";

interface IProps {
    departure: DepartureType
}

const StationsMapScheduleItem : React.FC<IProps> = observer(({departure}) => {
    return (
        <ListItem>
            <Grid container spacing={2}>
                <Grid item className={`schedule-time ${departure.delay > 0 ? "delayed" : ""}`}>
                    <ListItemText
                        primary={readableTimeFromDate(departure.date)}
                        secondary={departure.delay >= 0 ? ("+" + departure.delay) : null}
                    />
                </Grid>
                <Grid item className="schedule-departure">
                    <ListItemText
                        primary={<div>
                            <img alt={departure.product} src={trafficProductIcons[departure.product]} />
                            <span className={"lineName " + departure.product}>{departure.lineName}</span>
                            <span>{departure.stationName}</span>
                        </div>}
                        secondary={departure.direction}
                    />
                </Grid>
            </Grid>
        </ListItem>
    )
})

export default StationsMapScheduleItem;