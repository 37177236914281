import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {Box, Divider, Fab, Grid, IconButton, Link, Typography} from "@material-ui/core"
import {Close, DepartureBoard, Favorite, Help} from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            position: 'absolute !important' as "absolute",
            top: '10%',
            left: '10%',
            overflow: 'scroll',
            height: '100%',
            display: 'block',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            position: 'relative'
        },
        closeButton: {
            position: 'absolute',
            right: 5,
            top: 5,
            color: theme.palette.grey[500],
        },
        inlineIconCentered: {
            verticalAlign: 'middle'
        },
        margin: {
            margin: theme.spacing(1),
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        }
    }),
)


export default function RemoteDemo() {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Fab id={"fabRemoteDemo"} variant="extended" className={classes.margin} aria-label="demo" onClick={handleOpen}>
                <Help className={classes.extendedIcon} /> Mehr Infos
            </Fab>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <Grid container spacing={4}>
                            <Grid item>
                                <Box maxWidth={700}>
                                    <Typography variant="h4">Weil Wir Dich Hören</Typography>
                                    <Typography variant="h6">Das Werk „Weil Wir Dich Hören“ blickt von oben auf den Berliner Nahverkehr mitsamt Verspätungen und vertont diesen in Echtzeit – gespielt vom vermutlich größten Orchester Berlins.</Typography>
                                    <br/>
                                    <Typography variant="h6">Abfahrten werden auf der Karte mit Kreisen dargestellt (grün, wenn pünktlich, gelb, wenn leicht verspätet und rot, wenn stark verspätet). Zu jedem Verkehrsmittel (z.B. S-Bahn, Bus) gibt es ein Instrument, das bei jeder Abfahrt entsprechend der Verspätung einen anderen Ton spielt.</Typography>
                                    <br/>
                                    <Typography variant="h6">Das Werk kann direkt vom Handy aus gesteuert werden. So ist ein Reinzoomen in besonders interessante Bereiche möglich und außerdem können die letzten Abfahrten im Fahrplan <DepartureBoard/> gesehen werden.</Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h6">Simon Wietheger, Vincent Rahn / 2020&ndash;2021</Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="subtitle1">
                                        Gebaut mit<Link href="https://reactjs.org/" target="_blank" rel="noreferrer"> React</Link>,
                                        <Link href="https://nodejs.org/" target="_blank" rel="noreferrer"> Node.js</Link>,
                                        <Link href="https://expressjs.com/" target="_blank" rel="noreferrer"> Express</Link>,
                                        <Link href="https://tonejs.github.io/" target="_blank" rel="noreferrer"> Tone.js</Link>,
                                        <Link href="https://github.com/public-transport/hafas-client" target="_blank" rel="noreferrer"> hafas-client</Link>,
                                        <Link href="https://github.com/supremetechnopriest/react-idle-timer" target="_blank" rel="noreferrer"> react-idle-timer</Link> und
                                        <Link href="https://www.bvg.de/" target="_blank" rel="noreferrer"> <Favorite color={"secondary"} className={classes.inlineIconCentered}/></Link></Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h5">Besten Genuß mit dem Berliner Verkehr!</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
