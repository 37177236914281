import {vehicles} from "./constants";

function frequenciesFromVehicleDelay(vehicleType: string, delay: number) {
    let frequencies: (number | string) | (number | string)[]

    if (delay > 0) {
        if (delay <= 2)
            frequencies = vehicles[vehicleType].delayFrequency[0]
        else if (delay <= 5)
            frequencies = vehicles[vehicleType].delayFrequency[1]
        else
            frequencies = vehicles[vehicleType].delayFrequency[2]
    } else {
        frequencies = vehicles[vehicleType].frequency
    }

    return frequencies
}

function volumeFromDelay(delay: number) {
    if (delay > 0) {
        return (delay > 10) ? 0 : -10 + delay
    } else {
        return -20
    }
}

export {
    frequenciesFromVehicleDelay,
    volumeFromDelay
}
