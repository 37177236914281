const trailingZeroNumber = (number: number) : string => {
    return ((number < 10) ? "0" : "" ) + number
}

const readableDateFromDate = (date: Date) => {
    const fullYear = date.getFullYear().toString()
    return trailingZeroNumber(date.getDate()) +
        "." + trailingZeroNumber(date.getMonth() + 1) +
        "." + fullYear.substr(fullYear.length - 2)
}
const readableTimeFromDate = (date: Date) => {
    return trailingZeroNumber(date.getHours()) +
        ":" + trailingZeroNumber(date.getMinutes())
}

export {
    readableDateFromDate,
    readableTimeFromDate
}
