import React, {createRef} from 'react';
import 'leaflet/dist/leaflet.css';
import "@kenshooui/react-multi-select/dist/style.css"
import { useIdleTimer } from 'react-idle-timer'
import '../styles/App.css';
import StationsMap from "./StationsMap";
import StationsMapControls from "./StationsMapControls";
import {ScheduleProvider, scheduleStore} from "../models/ScheduleStore";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {StationsProvider, stationsStore} from "../models/StationsStore";
import Station from "../models/Station";
import Departure from "../models/Departure";
import {playDelaySound} from "../utils/generalAudio";
import {w3cwebsocket as W3CWebSocket} from "websocket";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#e79f06'
        },
        secondary: {
            main: '#f0d723'
        }
    },
    typography: {
        fontFamily: [
            'Vegur',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

interface IProps {
    presentationId: string | null
}

const WS_ADDRESS = (process.env.NODE_ENV === 'production') ? 'wss://weil-wir-dich-hoeren.de' : 'ws://localhost:8999';
const client = new W3CWebSocket(WS_ADDRESS);

const App : React.FC<IProps> = ({presentationId}) => {

    const mapRef = createRef<StationsMap>();
    const controlsRef = createRef<StationsMapControls>();

    const handleOnIdle = (event: any) => {
        console.log("Resetting settings as user is idle")
        window.location.reload();
    }
    
    const { reset } = useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: handleOnIdle,
        debounce: 500
    })
    console.log("Setup idle")

    client.onopen = () => {
        console.log('WebSocket Client Connected');
    };
    client.onmessage = (message) => {
        const data = JSON.parse(message.data as string);
        if(data.type === "STATIONS") {
            data.stations.forEach((station: any) => {
                stationsStore.addStation(Station.create({
                    id: station.id,
                    name: station.name,
                    lat: station.lat,
                    long: station.long
                }))
            });
        } else if(data.type === "DEPARTURES") {
            for (let stationId in data.departures) {
                let station = stationsStore.findStation(stationId);
                if(station !== null) {
                    data.departures[stationId].forEach((departure: any) => {
                        if (station!!.findDeparture(departure.id) == null) {
                            station!!.addDeparture(Departure.create({
                                id: departure.id,
                                date: new Date(departure.date),
                                delay: departure.delay,
                                product: departure.product,
                                lineName: departure.lineName,
                                direction: departure.direction,
                                stationName: station?.name
                            }))
                        }
                    })
                }
            }
        } else if(data.type === "GENERAL_DELAY") {
            playDelaySound(data.generalDelay, stationsStore.numberSelectedStations)
        } else if(data.type === "CONTROL") {
            if(data.id === presentationId) {
                reset();
                if ("zoom" in data.operations) {
                    if (data.operations.zoom === -1) {
                        mapRef.current?.zoomOut();
                    } else {
                        mapRef.current?.zoomIn();
                    }
                } else if ("scroll" in data.operations) {
                    mapRef.current?.scroll(data.operations.scroll);
                } else if ("reset" in data.operations) {
                    mapRef.current?.reset();
                    controlsRef.current?.reset();
                } else if ("log_toggle" in data.operations) {
                    controlsRef.current?.scheduleClick();
                }
            }
        }
    }

  return (
      <ThemeProvider theme={theme}>
          <ScheduleProvider value={scheduleStore}>
              <StationsProvider value={stationsStore}>
                  <StationsMapControls ref={controlsRef} isPresentation={presentationId !== null}/>
                  <StationsMap ref={mapRef} isPresentation={presentationId !== null}/>
              </StationsProvider>
          </ScheduleProvider>
      </ThemeProvider>
  );
}

export default App;
