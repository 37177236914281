import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {Box, Button, Divider, Fab, Grid, IconButton, Link, Typography} from "@material-ui/core"
import {Close, DepartureBoard, EditLocation, Favorite, Help, VolumeDown, VolumeUp} from "@material-ui/icons"
import {muteVolumeForDemo, playVehicleDemoSound, unmuteVolumeForDemo} from "../utils/generalAudio"
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import {trafficProductIcons, vehicles} from "../utils/constants";
import {frequenciesFromVehicleDelay} from "../utils/vehicleSounds";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            position: 'relative'
        },
        closeButton: {
            position: 'absolute',
            right: 5,
            top: 5,
            color: theme.palette.grey[500],
        },
        inlineIconCentered: {
            verticalAlign: 'middle'
        }
    }),
)

const demoVehicles = ["bus", "subway", "suburban", "regional", "tram", "ferry", "express"]
const demoDelays = {
    0: "0",
    1: "1-2",
    3: "3-5",
    6: "> 5"
}

export default function Demo() {
    const classes = useStyles()

    const [open, setOpen] = React.useState(false)
    const [previouslyMuted, setPreviouslyMuted] = React.useState(false)
    const handleOpen = () => {
        setPreviouslyMuted(muteVolumeForDemo())
        setOpen(true)
    };
    const handleClose = () => {
        if(!previouslyMuted)
            unmuteVolumeForDemo()
        setOpen(false)
    }

    const [vehicle, setVehicle] = React.useState<string>(demoVehicles[0])
    const handleVehicle = (event: React.MouseEvent<HTMLElement>, newVehicle: string) => {
        if (newVehicle !== null)
            setVehicle(newVehicle)
    }

    const [delay, setDelay] = React.useState<string>("0")
    const handleDelay = (event: React.MouseEvent<HTMLElement>, newDelay: string) => {
        if (newDelay !== null)
            setDelay(newDelay);
    }

    const playDemoSound = () => {
        const frequency = frequenciesFromVehicleDelay(vehicle, parseInt(delay))
        const instrument = vehicles[vehicle].instrument
        playVehicleDemoSound(instrument, frequency)
    }

    return (
        <div>
            <Fab id={"fabDemo"} color={"primary"} aria-label="demo" onClick={handleOpen}>
                <Help />
            </Fab>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
                            <Close />
                        </IconButton>
                        <Grid container spacing={4}>
                            <Grid item>
                                <Box maxWidth={700}>
                                    <Typography variant="h4">Über</Typography>
                                    <Typography variant="h6">Abfahrten werden auf der Karte mit Kreisen dargestellt (grün, wenn pünktlich, gelb, wenn leicht verspätet und rot, wenn stark verspätet). Sie können Stationen über <EditLocation/> (ab)wählen und die letzten Abfahrten im Fahrplan <DepartureBoard/> sehen.</Typography>
                                    <Typography variant="h6">
                                        Mit <VolumeDown className={classes.inlineIconCentered}/> und <VolumeUp className={classes.inlineIconCentered}/> können Sie die globale Lautstärke stummschalten bzw. maximieren. (De-)aktivieren Sie die Kontrollkästchen für <b>Abfahrten</b> und <b>Hintergrund</b>, um die Töne einzelner Abfahrten und die gemittelte Verspätung über die letzten Minuten individuell zu (de)aktivieren.</Typography>
                                </Box>
                                <Box my={2}>
                                    <Divider />
                                </Box>
                                <Box my={2}>
                                    <Typography variant="h6">Simon Wietheger, Vincent Rahn / 2020&ndash;2021</Typography>
                                </Box>
                                <Box mt={2}>
                                    <Typography variant="h6">Gebaut mit&nbsp;
                                        <Link href="https://reactjs.org/" target="_blank" rel="noreferrer">
                                            React
                                        </Link>,&nbsp;
                                        <Link href="https://nodejs.org/" target="_blank" rel="noreferrer">
                                            Node.js
                                        </Link>,&nbsp;
                                        <Link href="https://expressjs.com/" target="_blank" rel="noreferrer">
                                            Express
                                        </Link>,&nbsp;
                                        <Link href="https://tonejs.github.io/" target="_blank" rel="noreferrer">
                                            Tone.js
                                        </Link>,&nbsp;
                                        <Link href="https://github.com/public-transport/hafas-client" target="_blank" rel="noreferrer">
                                            hafas-client
                                        </Link>,&nbsp;
                                        <Link href="https://github.com/supremetechnopriest/react-idle-timer" target="_blank" rel="noreferrer">
                                            react-idle-timer
                                        </Link> und&nbsp;
                                        <Link href="https://www.bvg.de/" target="_blank" rel="noreferrer">
                                            <Favorite color={"secondary"} className={classes.inlineIconCentered}/>
                                        </Link></Typography>
                                </Box>
                                <Box>
                                    <Typography variant="h5">Genießen Sie den Berliner Verkehr!</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box mb={1}>
                                    <Typography variant="h4">Fahrzeug-Demo</Typography>
                                    <Typography variant="h6">Wählen Sie ein Fahrzeug und dessen Verspätung aus, um den spezifischen Ton zu hören.</Typography>
                                </Box>

                                <Box my={2}>
                                    <Typography variant="h5">Fahrzeug</Typography>
                                    <ToggleButtonGroup
                                        value={vehicle}
                                        exclusive
                                        onChange={handleVehicle}
                                        aria-label="vehicle">

                                        {demoVehicles.map((demoVehicle) =>
                                            <ToggleButton value={demoVehicle} aria-label={demoVehicle}>
                                                <img alt={demoVehicle} src={trafficProductIcons[demoVehicle]} height={"30px"}/>
                                            </ToggleButton>
                                        )}
                                    </ToggleButtonGroup>
                                </Box>


                                <Box my={2}>
                                    <Typography variant="h5">Verspätung in Minuten</Typography>
                                    <ToggleButtonGroup
                                        value={delay}
                                        exclusive
                                        onChange={handleDelay}
                                        aria-label="delay">

                                        {Object.entries(demoDelays).map(([index, demoDelay]) =>
                                            <ToggleButton value={index} aria-label={demoDelay}>
                                                <Typography>{demoDelay}</Typography>
                                            </ToggleButton>
                                        )}
                                    </ToggleButtonGroup>
                                </Box>

                                <Button variant="contained" size="large" color="primary" onClick={playDemoSound}>
                                    Ton abspielen
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
