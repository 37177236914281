import {FeatureGroup, Popup} from "react-leaflet";
import React from "react";
import StationsMapDeparture from "./StationsMapDeparture";
import {Map as LeafletMap} from "leaflet";
import {observer} from "mobx-react";
import {StationType} from "../models/Station";
import Departure, {DepartureType} from "../models/Departure";
import {useScheduleStore} from "../models/ScheduleStore";
import {getSnapshot} from "mobx-state-tree";

interface IProps {
    station: StationType,
    map?: LeafletMap,
}


const StationsMapStation: React.FC<IProps> = observer(({station, map}) => {
    const scheduleStore = useScheduleStore()

    const departurePassed = (departure: DepartureType) => {
        scheduleStore.addRecentDeparture(Departure.create(getSnapshot(departure)))
    }

    return (
        <div>
            <FeatureGroup>
                <Popup>
                    <span>{station.name}</span>
                </Popup>
                {station.departures.map((departure, index) =>
                    <StationsMapDeparture
                        key={index}
                        station={station}
                        departure={departure}
                        departurePassed={departurePassed}
                        map={map}/>
                )}
            </FeatureGroup>
        </div>
    )
})

export default StationsMapStation;