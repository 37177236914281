import React from "react"
import {List, Paper} from "@material-ui/core";
import {observer} from "mobx-react";
import {useScheduleStore} from "../models/ScheduleStore";
import StationsMapScheduleItem from "./StationMapScheduleItem";


const StationsMapSchedule : React.FC = observer(() => {
    const {recentDepartures} = useScheduleStore()

    return (
        <div id={"schedule"}>
            <Paper elevation={3}>
                <List className={"scheduleList"} dense={true}>
                    {recentDepartures.slice(0).reverse().map((departure, index) =>
                        <StationsMapScheduleItem
                            key={index}
                            departure={departure} />
                    )}
                </List>
            </Paper>
        </div>
    )
})

export default StationsMapSchedule;