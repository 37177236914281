import React from 'react';
import {Fab} from "@material-ui/core";
import {Add,ArrowBackIos, ArrowForwardIos,Remove} from "@material-ui/icons";

interface IProps {
    sendMessage: any
};

const RemoteMapControls : React.FC<IProps> = ({sendMessage}) => {

    const zoom = (direction: number) => {
        sendMessage({
            "type": "CONTROL_ZOOM",
            "param": direction
        });
    }

    const scroll = (direction: number) => {
        sendMessage({
            "type": "CONTROL_SCROLL",
            "param": direction
        });
    }

    return (
        <div className={"remoteMapControls"}>
            <div className={"zoom"}>
                <Fab id={"fabIn"} color="secondary" size="large" aria-label="zoomIn" onClick={() => zoom(1)}>
                    <Add />
                </Fab>
                <Fab id={"fabOut"} color="secondary" size="large" aria-label="zoomOut" onClick={() => zoom(-1)}>
                    <Remove />
                </Fab>
            </div>
            <div className={"position"}>
                <Fab id={"fabTop"} color="primary" size="large" aria-label="scrollTop" onClick={() => scroll(0)}>
                    <ArrowBackIos />
                </Fab>
                <br/>
                <Fab id={"fabLeft"} color="primary" size="large" aria-label="scrollLeft" onClick={() => scroll(3)}>
                    <ArrowBackIos />
                </Fab>
                <Fab id={"fabRight"} color="primary" size="large" aria-label="scrollRight" onClick={() => scroll(1)}>
                    <ArrowForwardIos />
                </Fab>
                <br/>
                <Fab id={"fabBottom"} color="primary" size="large" aria-label="scrollBottom" onClick={() => scroll(2)}>
                    <ArrowBackIos />
                </Fab>
            </div>
        </div>
    );
}

export default RemoteMapControls;